import { appConstants } from "../_constants";
import { history } from "../_helpers";

export const appActions = {
  updateApiHost(host) {
    return (dispatch) => dispatch({ type: appConstants.UPDATE_API_HOST, host });
  },

  moveTo(url) {
    history.push(`/${url}`);
    return (dispatch) => dispatch({ type: appConstants.MOVE_TO, url });
  },

  setpage(page, id = 0) {
    let page_new = page;

    if (id > 0) page_new += `/${id}`;

    if (page != "confs") history.push(`/${page_new}`);
    else history.push("/");

    return (dispatch) => dispatch({ type: appConstants.SET_MENU, page });
  },

  setlang(lang) {
    return (dispatch) => dispatch({ type: appConstants.SET_LANG, lang });
  },
};
