import React from "react";
import "../css/style.css";

import i18next from "i18next";
import CookieConsent from "react-cookie-consent";

export default class CookiePopup extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <CookieConsent
        location="bottom"
        buttonText={i18next.t("i_agree")}
        cookieName="myAwesomeCookieName2"
        expires={150}
        style={{
          paddingLeft: 130,
          zIndex: 9000,
          background: "#2B373B" 
        }}
        buttonStyle={{
          position: "absolute",
          left: 10,
          top: -3,
          backgroundColor: "#33AFEE",
          color: "#4e503b", 
          fontSize: "13px"
        }}
      >
        {i18next.t("cookie_1")}{" "}
        <a
          href="https://naviconf.com/cookie-agreement/"
          target="_blank"
          rel="nofollow"
          rel="noindex"
        >
          <span style={{ textDecoration: "underline" }}>{i18next.t("cookie_2")}</span>
        </a>{" "}
        {i18next.t("cookie_3")}{" "}
        <a
          href="https://naviconf.com/cookie/"
          target="_blank"
          rel="nofollow"
          rel="noindex"
          class="hrefline"
        >
          <span style={{ textDecoration: "underline" }}>cookie</span>
        </a>
      </CookieConsent>
    );
  }
}
