import { applyMiddleware, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "../_reducers";

const loggerMiddleware = createLogger({
  predicate: (getState, action) =>
    process.env.HARMONY_ENV === undefined ||
    process.env.HARMONY_ENV === "deployment",
});

export const store = createStore(
  rootReducer,
  //applyMiddleware(thunkMiddleware, loggerMiddleware)
  applyMiddleware(thunkMiddleware)
);
