import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { YMInitializer } from "react-yandex-metrika";
import "./style.css";

import { store } from "./_helpers";
import { App } from "./App";

import "./i18n";

const ym_id = process.env.REACT_APP_YM_ID;

// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

ReactDOM.render(
  <Provider store={store}>
    <YMInitializer
      accounts={[ym_id]}
      options={{ webvisor: true }}
      version="2"
    />
    <App />
  </Provider>,
  document.getElementById("root")
);

