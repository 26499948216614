import i18next from "i18next";

const code2text = {
  REQUIRED: "Обязательное поле",
  INVALID: "Некорректное значение",
  LEN: "Длина должна быть %d символов",
  MIN: "Минимальное значение: %d",
  MAX: "Максимальное значение: %d",
  GT: "Значение должно быть больше %d",
  GTE: "Значение должно быть больше или равно %d",
  LT: "Значение должно быть меньше %d",
  LTE: "Значение должно быть меньше или равно %d",
  MIN_LEN: "Длина должна быть больше %d символов",
  MAX_LEN: "Длина должна быть меньше %d символов",
  TOO_FEW_ELEM: "Минимальное количество элементов: %d",
  TOO_MANY_ELEM: "Максимальное количество элементов: %d",
  REQUIRED_WITHOUT: "Введите одно из полей",
  EMAIL: "Недопустимый формат для email",
  EMAIL_LIST: "Недопустимый формат для списка email",
};

const commonCode2text = {
  "Invalid request payload": "",
  BAD_REQUEST: "",
  "Entity already exists": "Такой объект уже существует",
};

export const setErrors = (component, e, convertKeyFn) => {
  const errors = {};
  const camel2snake = (str) =>
    str
      .replace(/\.?([A-Z])/g, ($1) => `_${$1.toLowerCase()}`)
      .replace(/^_/, "");
  if (undefined === convertKeyFn) {
    convertKeyFn = camel2snake;
  }
  if (e.data) {
    for (const item of e.data) {
      let text = item.value;
      const data = text.split(":");
      if (code2text[data[0]] !== undefined) {
        text = code2text[data[0]];
        if (data.length > 1) {
          text = text.replace("%d", data[1]);
        }
      }
      errors[convertKeyFn(item.key)] = text;
    }
  }
  let commonError = e.message;
  if (commonCode2text[commonError] !== undefined) {
    commonError = commonCode2text[commonError];
  }
  component.setState({
    commonError,
    error: { ...component.state.error, ...errors },
  });
};

export const alertError = (e) => {
  if (!e) {
    return;
  }
  alert(e.message);
};

export const responseErrorToText = error => {
  const data = error.response.data;
  let message = i18next.t(data.message);
  for (let key in data.errors) {
    message += " " + data.errors[key].map(item => i18next.t(item)).join(" ");
  }
  return message;
};
