import { scheduleConstants } from "../_constants";

const initialState = {
  sections: [],
  slots: [],
  themes: [],
  service_themes:[],
  themes_id: [],
  rooms: [],
  speakers: [],
  allLessons: [],
  curDate: null,
  isLoading: false,
  fileStatus: 0,
  fileData: null,
};

export function schedule(state = initialState, action) {
  if (action.type === scheduleConstants.SET_SCHEDULE) {
    return {
      ...state,
      sections: action.data,
    };
  }
  if (action.type === scheduleConstants.SET_SLOTS) {
    return {
      ...state,
      slots: action.data,
    };
  }
  if (action.type === scheduleConstants.SET_DATE) {
      
    return {
      ...state,
      slots:[],
      sections:[],
      curDate: action.data,
    };
  }
  if (action.type === scheduleConstants.SET_FILE_STATUS) {
    return {
      ...state,
      fileStatus: action.data,
    };
  }
  if (action.type === scheduleConstants.SET_FILE_DATA) {
    return {
      ...state,
      fileData: action.data,
    };
  }
  if (action.type === scheduleConstants.SET_ROOMS) {
    return {
      ...state,
      rooms: action.data,
    };
  }
  if (action.type === scheduleConstants.SET_THEMES) {
    return {
      ...state,
      themes: action.data,
    };
  }
  if (action.type === scheduleConstants.SET_SERVICE_THEMES) {
    return {
      ...state,
      service_themes: action.data,
    };
  }
  if (action.type === scheduleConstants.SET_SPEAKERS) {
    return {
      ...state,
      speakers: action.data,
    };
  }
  if (action.type === scheduleConstants.SET_LESSONS) {
    return {
      ...state,
      allLessons: action.data,
    };
  }
  if (action.type === scheduleConstants.SET_THEMES_ID) {
    return {
      ...state,
      themes_id: action.data,
    };
  }

  return state;
}
