import React from "react";
import CustomizedButton from "../_components/buttons/CustomizedButton";
import i18next from "i18next";
import { initLanguage } from "../_helpers";
import { store } from "../_helpers";
import { userActions } from "../_actions";
import { userService } from "../_services";
import { TextField } from "@mui/material";
import { responseErrorToText } from "../_helpers";
import { Snack } from "../_components";
import { NoAuthPage } from "./NoAuthPage";
import { history } from "../_helpers";
import { cn as lang_cn } from "../_lang/cn.json";
import { en as lang_en } from "../_lang/en.json";
import { ru as lang_ru } from "../_lang/ru.json";

import ruFlag from "../img/ru.png";
import enFlag from "../img/en.png";
import cnFlag from "../img/cn.png";


const loginIsEmail = process.env.REACT_APP_LOGIN_IS_EMAIL;

export default class PasswordForgotPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      alertShow: false,
      errType: "error",
      snackText: "",
    };
  }

  componentDidMount() {
    let lang = localStorage.getItem("navic_lang") ?? "ru";
    this.setState({ lang });
  }

  
  changeLang = () => {
    let { lang } = this.state;
    if (lang == "ru") lang = "en";
    else if (lang == "en") lang = "cn";
    else lang = "ru";

    localStorage.setItem("navic_lang", lang);
    this.setState({lang});
  };

  getI18N = (textKey) => {
    const { lang } = this.state;

    if (!lang) return;

    if (lang == "ru") {
      if (Object.keys(lang_ru.translation).indexOf(textKey) >= 0)
        return lang_ru.translation[textKey];
    }

    if (lang == "en")
      if (Object.keys(lang_en.translation).indexOf(textKey) >= 0)
        return lang_en.translation[textKey];

    if (lang == "cn")
      if (Object.keys(lang_cn.translation).indexOf(textKey) >= 0)
        return lang_cn.translation[textKey];

    return textKey;
  };

  sendResetEmail = (e) => {

    let self = this;

    e.preventDefault();
    store.dispatch(userActions.loading(true));
    userService.postWrapper(
      "api/password/forgot",
      { email: this.state.email },
      (data) => {
        store.dispatch(userActions.loading(false));
        this.setState({
          snackText:
          self.getI18N("email_with_reset_password_link_sent") + " "+this.state.email,
          alertShow: true,
          errType: "success",
        });
      },
      (error) => {
        store.dispatch(userActions.loading(false));
        this.setState({
          snackText: responseErrorToText(error),
          alertShow: true,
          errType: "error",
        });
      }
    );
  };

  render() {
    const form = (
      <form onSubmit={this.sendResetEmail}>
        <div>
          <TextField
            label="email"
            onChange={(e) => this.setState({ email: e.target.value })}
            variant="standard"
          />
        </div>
        <CustomizedButton
          type="submit"
          title={this.getI18N("reset_password")}
          prim
          className="form-button"
        />
      </form>
    );
    const snack = (
      <Snack
        open={this.state.alertShow}
        variant={this.state.errType}
        text={this.state.snackText}
        onClose={() => this.setState({ alertShow: false })}
      />
    );

    
    const formNoReg= (
      <form onSubmit={this.resetPassword}>
        <h3>
          Для сброса пароля свяжитесь с поддержкой мероприятия
        </h3>
        <CustomizedButton
          title="Назад"
          prim
          className="form-button"
          onClick={()=>history.push('/login')}
        />
      </form>
    );


    const { lang} = this.state;

    return (<>
    {process.env.REACT_APP_DISABLE_REG != "true" && <div style={{position: 'absolute',top:20, left:20, fontSize:20}}>
       <div className="lang">
                <button className="lang__btn" onClick={this.changeLang}>
                  {lang == "ru" && <img src={ruFlag} alt="" className="lang__flag" />}
                  {lang == "en" && <img src={enFlag} alt="" className="lang__flag" />}
                  {lang == "cn" && <img src={cnFlag} alt="" className="lang__flag" />}
                  <span  style={{fontSize:16}}>{lang}</span>
                </button>
              </div>
        </div>}
        {process.env.REACT_APP_DISABLE_REG != "true" && <NoAuthPage form={form} snack={snack} />}
        {process.env.REACT_APP_DISABLE_REG == "true" && <NoAuthPage form={formNoReg} snack={snack} />}
        </>);
  }
}
