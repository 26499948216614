import React from "react";
import { Grid, TextField, FormControl } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import StylizedInputLabel from "./StylizedInputLabel";
import StylizedHelper from "./StylizedHelper";

const styles = {
  input: {
    fontSize: 24,
    fontWeight: "bold",
    lineHeight: 1.21,
    letterSpacing: -0.48,
    color: "#0b1218",
    "&:disabled": {
      // TODO не работает
      color: "rgba(0, 0, 0, 0.38)",
    },
  },
};

function TextFieldCell(props) {
  const {
    id,
    xs,
    type,
    value,
    error,
    name,
    label,
    placeholder,
    onBlur,
    prefix,
    infoText,
    autoFocus,
    multiline,
    inputProps,
    InputProps,
    onChange,
    onLeave,
    size,
    disabled,
  } = props;

  // чтоб не вылазило за int32
  const inputSize = type === "number" && undefined === size ? 9 : size;

  function handleChange(e) {
    if (prefix) {
      e.target.value = e.target.value.substring(prefix.length);
    }
    e.target.value = sanitize(e.target.value);
    if (inputSize) {
      e.target.value = e.target.value.slice(0, inputSize);
    }
    onChange(e);
  }

  function sanitize(v) {
    return v
      .replace(/\u00A0/g, " ")
      .replace(/«/g, '"')
      .replace(/»/g, '"')
      .replace(/“/g, '"')
      .replace(/„/g, '"')
      .replace(/”/g, '"')
      .replace(/^\s*/, "");
  }

  function handleLeave(e) {
    if (onLeave) {
      onLeave(e);
    }
  }

  function getValue() {
    return (prefix || "") + (value || "");
  }

  const isError = Boolean(error);

  return (
    <Grid item xs={xs || 12}>
      <StylizedInputLabel disabled={disabled}>{label}</StylizedInputLabel>
      <FormControl fullWidth error={isError}>
        <TextField
          {...inputProps}
          id={id}
          name={name}
          placeholder={placeholder}
          type={type}
          value={getValue()}
          autoFocus={autoFocus}
          multiline={multiline}
          onChange={handleChange}
          onBlur={onBlur || handleLeave}
          InputProps={{ style: { ...styles.input, ...InputProps } }}
          inputProps={{
            ...inputProps,
            disabled,
            max: type === "date" ? "2999-31-12" : "",
          }}
        />
        <StylizedHelper>{isError ? error : infoText}</StylizedHelper>
      </FormControl>
    </Grid>
  );
}

const styledTextFieldCell = withStyles(styles, { withTheme: true })(
  TextFieldCell
);

export { styledTextFieldCell as TextFieldCell };
