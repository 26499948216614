import React from "react";

import Checkbox from "@mui/material/Checkbox";

import axios from "axios";
import CustomizedButton from "../_components/buttons/CustomizedButton";
import { agrUrl, serverUrl } from "../_constants";
import { history } from "../_helpers";
import { cn as lang_cn } from "../_lang/cn.json";
import { en as lang_en } from "../_lang/en.json";
import { ru as lang_ru } from "../_lang/ru.json";

const UNAUTH = "Error: Request failed with status code 401";

function getStorage(name) {
  return localStorage.getItem(name);
}

function setStorage(name, val) {
  localStorage.setItem(name, val);
}

export default class AgrPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      agreeOk: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    let lang = localStorage.getItem("navic_lang") ?? "ru";
    this.setState({ lang });
  }

  getI18N = (textKey) => {
    const { lang } = this.state;

    if (!lang) return;

    if (lang == "ru") {
      if (Object.keys(lang_ru.translation).indexOf(textKey) >= 0)
        return lang_ru.translation[textKey];
    }

    if (lang == "en")
      if (Object.keys(lang_en.translation).indexOf(textKey) >= 0)
        return lang_en.translation[textKey];

    if (lang == "cn")
      if (Object.keys(lang_cn.translation).indexOf(textKey) >= 0)
        return lang_cn.translation[textKey];

    return textKey;
  };

  clickAgr = () => {
    // this.setState({slotInfoOpen:true});
    window.open(agrUrl);
  };

  clickLic = () => {
    let urlLic = "https://naviconf.com/docs/eula/";
    window.open(urlLic);
  };

  handleSubmit = (e) => {
    const token = getStorage("token_tmp");

    const { agreeOk, isLoading } = this.state;

    const self = this;

    if (agreeOk) {
      this.setState({ isLoading: true });

      axios.defaults.headers.post["Content-Type"] = "application/json";
      axios.defaults.withCredentials = true;
      axios.defaults.headers.post.Authorization = `Bearer ${token}`;

      axios.post(`${serverUrl}api/sign/user-agreement`, {}).then(
        (user) => {
          self.setState({ isLoading: false });
          console.log(user);
          setStorage("token", token);

          let nc_red = localStorage.getItem("navic_redirect");
          localStorage.setItem("navic_redirect", "");

          if (nc_red) {
            localStorage.setItem("tickets_open", "1");
            history.push(nc_red);
          } else history.push("/");
        },
        (error) => {
          if (error == UNAUTH) {
            history.push("/login");
          } else {
            self.setState({ isLoading: false });
            alert(error);

            console.log(error);
          }
        }
      );
    }
  };

  render() {
    const { agreeOk, isLoading } = this.state;

    return (
      <div>
        {!isLoading && (
          <div
            style={{ width: "100%", height: "100%", verticalAlign: "center" }}
          >
            <div style={{ width: 600, margin: "0 auto", paddingTop: "30vh" }}>
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  fontSize: 20,
                  marginLeft: -3,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Checkbox
                  color="default"
                  style={{ paddingLeft: 0, paddingTop: 0 }}
                  checked={agreeOk}
                  onClick={() => this.setState({ agreeOk: !agreeOk })}
                />
                <p>
                  {this.getI18N("pd_agr_1")}{" "}
                  <span
                    style={{ color: "blue", textDecoration: "underline" }}
                    onClick={this.clickAgr}
                  >
                    {this.getI18N("pd_agr_2")}
                  </span>{" "}
                  {this.getI18N("pd_agr_3")}{" "}
                  <span
                    style={{ color: "blue", textDecoration: "underline" }}
                    onClick={this.clickLic}
                  >
                    {this.getI18N("lic_agr")}
                  </span>
                </p>
              </div>
              <div
                className="form-group"
                style={{
                  marginTop: 5,
                  justifyContent: "space-between",
                  flex: 1,
                  display: "flex",
                }}
              >
                <CustomizedButton
                  style={{ marginBottom: 5 }}
                  onClick={this.handleSubmit}
                  id="category-button-add"
                  title={this.getI18N("accept")}
                  prim
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
