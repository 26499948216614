import { orgConferencesConstants } from "../_constants";

export const orgConferencesActions = {
  load,
  del,
};

function load(data) {
  return { type: orgConferencesConstants.LOAD, data };
}

function del(id) {
  return { type: orgConferencesConstants.DELETE, id };
}
