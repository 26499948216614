import React from "react";
import EntityItemButtons from "./EntityItemButtons";

export default class EntityItem extends React.Component {
  getValue = (field) => {
    if (this.props.data) {
      return field.convertor
        ? field.convertor(this.props.data)
        : this.props.data[field.name];
    } else {
      return "";
    }
  };

  render() {
    return (
      <div className={`tbl__body-row ${this.props.class}`}>
        <div className="tbl__items">
          {this.props.fields &&
            this.props.fields.map((field, index) => (
              <div className={`tbl__item ${field.class}`} key={field.name}>
                <span>{this.getValue(field)}</span>
              </div>
            ))}

          <EntityItemButtons
            id={this.props.data.id}
            actions={this.props.actions(this.props.data)}
          />
        </div>
      </div>
    );
  }
}
