import React from "react";
import CustomizedButton from "../_components/buttons/CustomizedButton";
import { history, initLanguage } from "../_helpers";
import { cn as lang_cn } from "../_lang/cn.json";
import { en as lang_en } from "../_lang/en.json";
import { ru as lang_ru } from "../_lang/ru.json";

export default class ModerationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      alertShow: false,
      errType: "error",
      snackText: "",
    };
    
  }

  componentDidMount() {
    let lang = localStorage.getItem("navic_lang") ?? "ru";
    this.setState({ lang });
    
  }

  getI18N = (textKey) => {
    const { lang } = this.state;

    if (!lang) return;

    if (lang == "ru") {
      if (Object.keys(lang_ru.translation).indexOf(textKey) >= 0)
        return lang_ru.translation[textKey];
    }

    if (lang == "en")
      if (Object.keys(lang_en.translation).indexOf(textKey) >= 0)
        return lang_en.translation[textKey];

    if (lang == "cn")
      if (Object.keys(lang_cn.translation).indexOf(textKey) >= 0)
        return lang_cn.translation[textKey];

    return textKey;
  };

  render() {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            marginLeft: 30,
            marginRight: 30,
            marginBottom: 20,
            fontSize: 30,
          }}
        >
          {this.getI18N("access_to_events_after_accept")}
        </div>
        <CustomizedButton
          title={this.getI18N("return")}  
          prim
          className="form-button"
          onClick={() => history.push("/")}
        />
      </div>
    );
  }
}
