import React from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme, withStyles } from "@material-ui/core/styles";
import { Drawer } from "@material-ui/core";
import classNames from "classnames";
import {
  faCog,
  faHome,
  faPaste,
  faSignOutAlt,
  faUsers,
  faList,
  faCode,
  faUnlock,
} from "@fortawesome/free-solid-svg-icons";
import CodeIcon from "@material-ui/icons/Code";
import { appActions } from "../_actions";
import HeadMenu from "./HeadMenu";
import { AppMenu } from "./AppMenu";
import { Menu } from "./buttons/Menu";
import "./layout.css";

const theme = createMuiTheme();
const drawerWidth = 300;
const styles = {
  hide: {
    visibility: "hidden",
  },

  drawer: {
    flexShrink: 0,
    whiteSpace: "nowrap",
    zIndex: 10,
  },
  gray: {
    backgroundColor: "rgba(11, 18, 24, 0.06)",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: "100px !important",
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    width: `calc(100% - ${theme.spacing(7) + 1}px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${theme.spacing(9) + 1}px)`,
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    position: "relative",
    overflowX: "hidden",
  },
};

class DefaultLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: props.title,
      pageBreadcrumbs: props.pageBreadcrumbs,
      searchText: "",
      location: props.location.pathname.substr(1),
      open: (localStorage.getItem("menu_close") || "0") === "0",
      menu: [],
    };

    this.goTo = this.goTo.bind(this);
  }

  goTo(path) {
    this.setState({ location: path });
    this.props.dispatch(appActions.moveTo(path));
  }

  setPageTitle = (title) => {
    this.setState({ pageTitle: title });
  };

  handleDrawerToggle = () => {
    const isOpen = !this.state.open;
    localStorage.setItem("menu_close", isOpen ? "0" : "1");
    this.setState({ open: isOpen });
  };

  render() {
    const Component = this.props.component;
    const { classes } = this.props;
    return (
      <div>
        <Component setPageTitle={this.setPageTitle} {...this.props} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return {
    user,
    users,
  };
}

const connectedDefaultLayout = connect(mapStateToProps)(DefaultLayout);
const styledDefaultLayout = withStyles(styles, { withTheme: true })(
  connectedDefaultLayout
);
export { styledDefaultLayout as DefaultLayout };
