import React from "react";
import i18next from "i18next";
import LoginIcon from '@mui/icons-material/Login';

export default class ItemButtonLogin extends React.Component {
  render() {
    return (
      <div title={i18next.t("login")} style={{ width: 20, marginLeft: 10 }}>
        <LoginIcon
          width="15"
          height="15"
          onClick={(e) => this.props.action(this.props.id)}
        />
      </div>
    );
  }
}
