import FormHelperText from "@material-ui/core/FormHelperText";
import { withStyles } from "@material-ui/core";

const StylizedHelper = withStyles({
  root: {
    fontSize: 17,
    lineHeight: 1.29,
    letterSpacing: 0.73,
    color: "rgba(11, 18, 24, 0.46)",
    marginTop: 14,
  },
  error: {
    fontSize: 15,
    color: "blue",
  },
})(FormHelperText);

export default StylizedHelper;
