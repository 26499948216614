import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  inputBox: {
    fill: "#000",
    height: "34px",
    width: "34px",
    "&:hover": {
      background: "none !important",
    },
    "&:disabled": {
      fill: "#a4a4a4",
    },
    paddingLeft: ".3em",
  },
  text: {
    verticalAlign: "middle",
    paddingLeft: ".5em",
  },
};

class StylizedCheckbox extends React.Component {
  render() {
    const {
      labelText,
      classes,
      id,
      name,
      color,
      onChange,
      checked,
      error,
      style,
      value,
      disabled,
    } = this.props;
    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              id={id}
              name={name}
              color={color}
              onChange={onChange}
              checked={checked}
              error={error}
              style={style}
              value={value || "on"}
              icon={<CheckBoxOutlineBlankIcon className={classes.inputBox} />}
              checkedIcon={<CheckBoxIcon className={classes.inputBox} />}
              className={classes.inputBox}
            />
          }
          label={<span className={classes.text}>{labelText}</span>}
          disabled={disabled}
        />
      </div>
    );
  }
}

const styledCheckbox = withStyles(styles, { withTheme: true })(
  StylizedCheckbox
);
export { styledCheckbox as StylizedCheckbox };
