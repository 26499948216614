import { orgConferencesConstants } from "../_constants";

const initialState = [];

export function orgConferences(state = initialState, action) {
  switch (action.type) {
    case orgConferencesConstants.LOAD:
      return action.data;
    case orgConferencesConstants.DELETE:
      return state.filter(
        (conference) => parseInt(conference.id) !== parseInt(action.id)
      );
    default:
      return state;
  }
}
