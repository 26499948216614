import React from "react";
import i18next from "i18next";
import { cn as lang_cn } from "../_lang/cn.json";
import { en as lang_en } from "../_lang/en.json";
import { ru as lang_ru } from "../_lang/ru.json";
import { history } from "../_helpers";

import ruFlag from "../img/ru.png";
import enFlag from "../img/en.png";
import cnFlag from "../img/cn.png";

export default class VerifyPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { lang: "ru" };
  }

  componentDidMount() {
    let lang = localStorage.getItem("navic_lang") ?? "ru";
    this.setState({ lang });
  }

  changeLang = () => {
    let { lang } = this.state;
    if (lang == "ru") lang = "en";
    else if (lang == "en") lang = "cn";
    else lang = "ru";

    localStorage.setItem("navic_lang", lang);
    this.setState({ lang });
  };

  getI18N = (textKey) => {
    const { lang } = this.state;

    if (!lang) return;

    if (lang == "ru") {
      if (Object.keys(lang_ru.translation).indexOf(textKey) >= 0)
        return lang_ru.translation[textKey];
    }

    if (lang == "en")
      if (Object.keys(lang_en.translation).indexOf(textKey) >= 0)
        return lang_en.translation[textKey];

    if (lang == "cn")
      if (Object.keys(lang_cn.translation).indexOf(textKey) >= 0)
        return lang_cn.translation[textKey];

    return textKey;
  };

  render() {
    const { lang } = this.state;

    return (
      <div style={{ width: "100%", height: "100%", verticalAlign: "center" }}>
        <div style={{ position: "absolute", top: 20, left: 20, fontSize: 20 }}>
          <div className="lang">
            <button className="lang__btn" onClick={this.changeLang}>
              {lang == "ru" && (
                <img src={ruFlag} alt="" className="lang__flag" />
              )}
              {lang == "en" && (
                <img src={enFlag} alt="" className="lang__flag" />
              )}
              {lang == "cn" && (
                <img src={cnFlag} alt="" className="lang__flag" />
              )}
              <span style={{ fontSize: 16 }}>{lang}</span>
            </button>
          </div>
        </div>
        <div
          style={{
            width: 400,
            margin: "0 auto",
            paddingTop: "30vh",
            fontSize: 20,
          }}
        >
          <div>
            <div style={{ paddingBottom: 20 }}>
              {this.getI18N("registry_nc_success")}
            </div>
            <button onClick={() => history.push("/")}>
              <span
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  fontSize: 20,
                }}
              >
                {this.getI18N("move_to_profile")}
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}
