import { InputLabel, withStyles } from "@material-ui/core";

const StylizedInputLabel = withStyles({
  root: {
    color: "#0b1218",
    fontSize: 17,
    fontWeight: 500,
    lineHeight: 1.29,
    letterSpacing: 0.66,
  },
})(InputLabel);

export default StylizedInputLabel;
