import React from "react";
import CustomizedButton from "./buttons/CustomizedButton";

export class SendButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
    };
    this.isComponentMounted = false;
  }

  componentDidMount() {
    this.isComponentMounted = true;
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  handleClick = () => {
    /*
        this.setState({inProgress: true}, () => {
            this.props
                .onClick()
                .then(() => {
                    if (this.isComponentMounted) {
                        this.setState({inProgress: false});
                    }
                });
        });
        */

    this.props.onClick();
  };

  render() {
    return (
      <CustomizedButton
        id={this.props.id}
        disabled={this.props.disabled || this.state.inProgress}
        onClick={this.handleClick}
        prim={this.props.prim}
        title={this.props.title}
        className={this.props.className}
      />
    );
  }
}
