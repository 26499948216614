import React from "react";
import ItemButtonEdit from "./ItemButtons/ItemButtonEdit";
import ItemButtonDelete from "./ItemButtons/ItemButtonDelete";
import ItemButtonLogin from "./ItemButtons/ItemButtonLogin";
import ItemButtonEmpty from "./ItemButtons/ItemButtonEmpty";

export default class EntityItemButtons extends React.Component {
  render() {
    return (
      <div className="item-edit">
        {this.props.actions.edit ? (
          <ItemButtonEdit id={this.props.id} action={this.props.actions.edit} />
        ) : (
          <ItemButtonEmpty />
        )}
        {this.props.actions.delete ? (
          <ItemButtonDelete
            id={this.props.id}
            action={this.props.actions.delete}
          />
        ) : (
          <ItemButtonEmpty />
        )}
        {this.props.actions.login && <ItemButtonLogin
            id={this.props.id}
            action={this.props.actions.login}
          />}
      </div>
    );
  }
}
