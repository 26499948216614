import React from "react";
import i18next from "i18next";
import "../css/style.css";


export class HelpPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false };
  }

  componentDidMount() {}

  render() {

    return (
      <div className="layout">
        <h1 className="layout__title">{i18next.t("helpinfo")}</h1>
        
      </div>
    );
  }
}
