import React from "react";
import { userService } from "../../_services";
import EntityHeader from "./EntityHeader";
import EntityList from "./EntityList";
import { UpsertDialog } from "./UpsertDialog";
import "../../css/style.css";
import { store } from "../../_helpers";
import { userActions } from "../../_actions";

class EntityPage extends React.Component {
  static NULL_FILLER = "NULL";
  static DIALOG_FIELDS_TYPE_CONFERENCE = "conference";

  constructor(props) {
    super(props);

    this.state = {
      openDialog: false,
      dialogFields: {},
      id: 0,
    };
  }

  componentDidMount() {
    this.props.loader();
  }

  fillDialogField = (field, value) => {
    return {
      value,
      required: this.props.dialog.fields[field].required,
      label: this.props.dialog.fields[field].label,
      type: this.props.dialog.fields[field].type,
      options: this.props.dialog.fields[field].options,
      subdialog: this.props.dialog.fields[field].subdialog,
    };
  };

  addEntity = () => {
    let dialogFields = {};
    for (let field in this.props.dialog.fields) {
      if (this.props.dialog.fields[field].add) {
        dialogFields[field] = this.fillDialogField(
          field,
          this.props.dialog.fields[field].default
        );
      }
    }

    this.setState({
      openDialog: true,
      id: 0,
      dialogFields,
    });
  };

  editEntity = (id) => {
    const entities = this.props.list.entities;

    let dialogFields = {};
    for (let i = 0; i < entities.length; i++) {
      if (entities[i].id === id) {
        for (let field in this.props.dialog.fields) {
          if (this.props.dialog.fields[field].edit) {
            dialogFields[field] = this.fillDialogField(
              field,
              entities[i][field]
            );
          }
        }
        break;
      }
    }

    this.setState({
      id,
      openDialog: true,
      dialogFields,
    });
  };

  deleteEntity = (id) => {
    const reqStr = `${this.props.endpoints.delete}/${id}`;

    store.dispatch(userActions.loading(true));
    userService.delWrapper(
      reqStr,
      (data) => {
        this.props.loader();
      },
      (error) => {
        store.dispatch(userActions.loading(false));
        if (error !== "") {
          this.setState({ error });
          alert(error);
        }
      }
    );
  };

  getHeaderActions = () => {
    const availableActions = { add: this.addEntity };
    let headerActions = {};

    if (this.props.header.actions) {
      for (let action in availableActions) {
        if (action in this.props.header.actions) {
          headerActions[action] = this.props.header.actions[action];
          headerActions[action].action = availableActions[action];
        }
      }
    }
    
    return headerActions;
  };

  getListActions = (item) => {
    const availableActions = {
      edit: (e) => this.editEntity(e),
      delete: (e) => this.deleteEntity(e),
      login: (e) => userActions.superAuth(e),
    };
    let listActions = {};

    for (let action in availableActions) {
      if (this.props.list.actions(item).indexOf(action) !== -1) {
        listActions[action] = availableActions[action];
      }
    }
    return listActions;
  };

  render() {
    const entities = this.props.list.entities;

    return (
      <div className="layout">
        <EntityHeader
          title={this.props.header.title}
          description={this.props.header.description}
          actions={this.getHeaderActions()}
        />
        <EntityList
          fields={this.props.list.fields}
          data={entities}
          itemActions={this.getListActions}
          classes={this.props.list.classes}
        />
        {this.props.dialog && <UpsertDialog
          title={this.props.dialog.title}
          id={this.state.id}
          dialogOpen={this.state.openDialog}
          dialogFields={this.state.dialogFields}
          endpoints={this.props.endpoints}
          loader={this.props.loader}
          closer={() => this.setState({ openDialog: false })}
          getPostData={this.props.dialog.getPostData}
        />}
      </div>
    );
  }
}

export { EntityPage };
