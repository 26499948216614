import React from "react";
import { Redirect, Route } from "react-router-dom";
import { DefaultLayout } from "./DefaultLayout";

function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1")}=([^;]*)`
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function getStorage(name)
{
	return localStorage.getItem(name);
}

// <Component {...props} />

export function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        getStorage("token") ? (
          <DefaultLayout title={rest.title} component={Component} {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login" + window.location.search,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
