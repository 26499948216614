import React from "react";

export default class EntityHeader extends React.Component {
  render() {
    return (
      <div className="layout__contains">
        <div>
          <h1 className="layout__title">{this.props.title}</h1>
          <div>{this.props.description}</div>
        </div>
        <div className="layout__btn">
          {this.props.actions.add && <button className="btn" onClick={this.props.actions.add.action}>
            {this.props.actions.add.title}
          </button>}
        </div>
      </div>
    );
  }
}
