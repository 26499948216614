import React from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";

class NoAuthPage extends React.Component {
  render() {
    return (
      <div className="noauth-page">
        {this.props.form}
        {this.props.snack}
        {this.props.user.isLoading && (
          <div className="shadow">
            <div className="shadow_circle">
              <CircularProgress />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return {
    user,
  };
}

const connectedNoAuthPage = connect(mapStateToProps)(NoAuthPage);
export { connectedNoAuthPage as NoAuthPage };
