import React from "react";
import "../css/style.css";
import i18next from "i18next";

export class SuppPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false };
  }

  componentDidMount() {}

  render() {
    const emailTo = "support@naviconf.com";

    return (
      <div className="layout">
        <h1 className="layout__title">{i18next.t("support")}</h1>
        <a
          style={{ textColor: "#00f", marginTop: 10 }}
          href={"mailto:" + emailTo}
        >
          {i18next.t("support_to_navic")}
        </a>
      </div>
    );
  }
}
